var slick = require('slick-carousel');
var hoverIntent =require('jquery-hoverintent');

if ("ontouchstart" in window || navigator.msMaxTouchPoints){isTouch = true;} else {isTouch = false;}

function ckHidden(){
	if ($('#menuBtn').is(':visible')){
		$('nav#main').css({left: "100%"});
	} else {
		$('nav#main').css({left: "auto"});
	}
}

function equalHeight(group) {
	tallest = 0;
	group.each(function() {
		thisHeight = $(this).outerHeight();
		if(thisHeight > tallest) {
			tallest = thisHeight;
		}
	});
	group.height(tallest);
}

function hps_height(){
  var showW = $('#homepage_show').width();
  if (showW <= 750){
	  var showH = showW * 0.733;
  } else {
	  var showH = showW * 0.375;
  }
  //$('#homepage_show').height(showH);	
}


function nl_placeholder() {
	var winW = $(window).width();
	var ph_textLg = "Your email here";
	var ph_textSm = "SUBSCRIBE TO OUR NEWSLETTER";
	
	if (winW > 600) {
		$('footer .newsletter input[type="text"]').attr('placeholder', ph_textLg);
	} else {
		$('footer .newsletter input[type="text"]').attr('placeholder', ph_textSm);
	}
}



function latestnews_setup(){

	var winW = $(window).width();
	var slideCount = $('#news_slider .news_slide').length;
	
	if (winW > 880) {
		var sliderWidth = 33.3333 * slideCount;
		$('#news_next, #news_prev').show();
		if (slideCount < 4) {
			$('#news_next, #news_prev').hide();
		}
	}
	if (winW <= 880) {
		var sliderWidth = 50 * slideCount;
		$('#news_next, #news_prev').show();
		$(".news_slide-info").css({'width':'100%'});

		if (slideCount < 3) {
			$('#news_next, #news_prev').hide();
		}
	}
	if (winW <= 800) {
		var sliderWidth = 100 * slideCount;
		$('#news_next, #news_prev').show();
		if (slideCount < 2) {
			$('#news_next, #news_prev').hide();
		}
	}

	$('#news_slider').width(1125 * (slideCount/3)+'px');

	if (winW > 1125) {
		//$("#latest_news").width("1125px");
	} else {
		//$("#latest_news").width("100%");
	}


	$('#news_slider').width(sliderWidth +"%");
	$('#news_slider .news_slide').css({width: 100 / slideCount +"%"});
}

// news_slide-img
function slideImgH(){
	$('.news_slide-img').each(function(){
		var slideW = $(this).width();
		var heightTo = slideW * 0.47826;
		$(this).height(heightTo);
	});
}

function newsNext(speed) {
	var winW = $(window).width();
		
		if (winW > 880) {
			var slideAmount = 33.3333;
		}
		if (winW <= 880) {
			var slideAmount = 50;
		}
		if (winW <= 460) {
			var slideAmount = 100;
		}

		$('#news_slider').animate({left: "-375px"}, speed, 'swing', function(){
			$('#news_slider .news_slide:first').appendTo('#news_slider');
			$('#news_slider').css({left: 0});
		});	
}

function newsPrev(speed) {
	var winW = $(window).width();
		
		if (winW > 880) {
			var slideAmount = 33.3333;
		}
		if (winW <= 880) {
			var slideAmount = 50;
		}
		if (winW <= 460) {
			var slideAmount = 100;
		}
		
		$('#news_slider').css({left: "-375px"});
		$('#news_slider .news_slide:last').prependTo('#news_slider');
		$('#news_slider').animate({left: 0}, speed, 'swing');	
}

// Footer links sizer
function f_sizer() {
	var f_linkW = $('#footer_links a').outerWidth();
	var f_height = $('footer').height();
	$('#footer_links a').height(f_linkW);	
}

//Image Source Swap
function imgSwap() {
	var winWidth = $(window).width();
	$('img').each(function(){
		var fullImg = $(this).attr('data-fullimg');
		var altImg = $(this).attr('data-altimg');
	
		if (altImg){
			if (winWidth > 750) {
				$(this).attr('src', fullImg);
			} 
			if (winWidth <= 750) {
				$(this).attr('src', altImg);
			}
		}
	});
}

function bodyContent_pad(){
	var bcW = $('#body_content').width();
	$('#body_content, ul.sidenav').css({"padding-top": bcW * 0.07});
	$('#apply_block').css({"padding-bottom": bcW * 0.07, "margin-bottom": bcW * 0.07});
}

function careerSearch() {
	if ($('#body_content .career_search').length) {
		var winW = $(window).width();
		var searchHeight = $('#body_content .career_search').outerHeight();
		$('#body_content').css({"padding-top": 0});
		if (winW <= 880) {
			$('#search_sort').css({"margin-top": searchHeight - 20+"px"});
		} else {
			$('#search_sort').css({"margin-top": searchHeight+"px"});
		}
	}	
}

function jumpToScroll(jumpTo){
    $('html,body').animate({scrollTop: $(jumpTo).offset().top}, 'slow');
}

$(window).on('load',function() {
	equalHeight($('.doc span'));
});

function resizeNewsSlider(){
	var winW = $(window).width();

	if (winW>880) {
		//$('#news_slider .news_slide').css({'width': '33%'});
		$('.news_slide-img img.standard').show();
		$('.news_slide-img img.large').hide();

	} else if (winW>800) {
		//$('#news_slider .news_slide').css({'width': '50%'});
		$('.news_slide-img img.standard').show();
		$('.news_slide-img img.large').hide();

	} else {
		//$('#news_slider .news_slide').css({'width': '100%'});
		$('.news_slide-img img.standard').hide();
		$('.news_slide-img img.large').show();
		$('.news_slide-img img.large').attr('style="width: 100%;"');
	}
}


$(document).ready( function() {
	resizeNewsSlider();

	// menu
		$('#main li ul').each(function(){
			$(this).addClass('drop_menu');
		});
		
		ckHidden();
		$('#menuBtn').on('click', function(){
			$('nav#main').animate({left: 20},500);
			return false;
		});
		$('#menuClose').on('click', function(){
			$('nav#main').animate({left: "100%"},500);
			return false;
		});
		
		$('#main li a').on('click', function(){
			var whichDrop = $(this).parent('li').children('ul.drop_menu');
			if (whichDrop.length == 1){
				return false;
			} else {
				return true;
			}
		});
	
	// images
		$('img').each(function(){
			var maxWidth = $(this).attr('width');
			$(this).css({"max-width": maxWidth + "px"});
			
			var imgSrc = $(this).attr('src');
			$(this).attr('data-fullimg', imgSrc);
		});
		imgSwap();
	
	hps_height();
	f_sizer();
	nl_placeholder();
	
	bodyContent_pad();
	careerSearch();
	
	$('a').on('click', function(){
		var whereToGo = $(this).attr('href');
		if (whereToGo === '#'){
			return false;
		} else if (/\//i.test(whereToGo)) {
			return true;
		} else {
			jumpToScroll(whereToGo);
			return false;
		}	
	});
	
	$('#main li').not('#main li li').hoverIntent(function(){
		$(this).addClass('hovered');
		$('ul.drop_menu',this).slideDown(400);
	}, function(){
		$(this).removeClass('hovered');
		$('ul.drop_menu',this).slideUp(200);
	});
	
	
// Header Search
	$('#search_trigger').on('click', function(){
		var winW = $(window).width();
		if (winW <= 880) {
			var searchWidth = $('#input_search').width();
			
			if ($('#input_search').val().length > 0) {
				return true;
			} else {
				if (searchWidth == 0) {
					$('#input_search').animate({width: winW - 48, "padding-left": 30, "padding-right": 30}, 500);
				} else {
					$('#input_search').animate({width: 0, "padding-left": 0, "padding-right": 0}, 500, function(){
						$(this).val('');
					});
				}
				return false;
			}
		}
	});
	
	// Homepage slideshow
    $("#homepage_show").slick({
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
		arrows: false
    });

    $("#news_slider").slick({
        autoplay: false,
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $("#news_arrows"),
        prevArrow:'<a id="news_prev" href="#"><i class="fa fa-long-arrow-left" aria-hidden="true"></i></a>',
        nextArrow:'<a id="news_next" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>',

        responsive: [
        {
            breakpoint: 880,
            settings: {
            slidesToShow: 2
            }
        },
        {
            breakpoint: 800,
            settings: {
            slidesToShow: 1
            }
        }
        ]
	});

		
	//latestnews_setup();
	
	//var slideSpeed = 600;
	//$('#news_next').on('click', function() {
		//newsNext(slideSpeed);
		//return false;
	//});
	//$('#news_prev').on('click', function() {
		//newsPrev(slideSpeed);
		//return false;
	//});
	
	equalHeight($('.news_slide-info h6, .news_slide-info ul'));
	$('#news_clips .news_slide:first').attr('id', 'first-news_clip');
	
	var navCopy = $('ul.sidenav').clone();
	$('<div id="sidenav_top"></div>').prependTo('#page_content');
	$('#sidenav_top').html(navCopy);
	if( $('#sidebar_content').data('page') == "newsletter") {
		$('#sidenav_top').attr('data-page', 'newsletter');
	}
	
	
	$('.sidenav').each(function(){
		var sidebarTitle = $('li.overview a', this).text();
		$('li.overview a', this).remove();
		$('li.overview', this).text(sidebarTitle);
	});
	
	$('.documents a').each(function(){
		$(this).wrapInner('<span />');
	});
	
	
	$('iframe').not('.map iframe').each(function(){
		$(this).wrap('<div class="video-container" />');
	});
	
	
	
	$('dt').on('click', function(){
		var myParent = $(this).parent();
		if ($(this).hasClass('opened')){
			$('dd',myParent).slideToggle(400);
			$(this).toggleClass('opened');
			$('span',this).removeClass('arrowDown');
			
		} else {
			$('dt').removeClass('opened');
			$('dd').slideUp(400);
			$(this).addClass('opened');
			$('dd',myParent).slideDown(400);
			$('dt span').removeClass('arrowDown');
			$('span',this).addClass('arrowDown');
		}
	});
	
	
	

if (isTouch == false){
	
	
		
}
		
if (isTouch == true){
	
}

	
	var windowChange = $(window).width();
	$(window).resize(function(){
		if ($(window).width() != windowChange) {
			windowChange = $(window).width();

			resizeNewsSlider();
			ckHidden();
			imgSwap();
			hps_height();
			f_sizer();
			nl_placeholder();
			
			bodyContent_pad();
			careerSearch();
			
			//latestnews_setup();
			$('.news_slide-info h6, .news_slide-info ul').height("auto");
			equalHeight($('.news_slide-info h6, .news_slide-info ul'));

			$('.doc span').height("auto");
			equalHeight($('.doc span'));
			
			if (windowChange > 881) {
				$('#input_search').css({width: 200, "padding-left": 30, "padding-right": 30});
			} else {
				$('#input_search').css({width: 0, "padding-left": 0, "padding-right": 0}).val('');
			}
			
		}
	});	
	
});